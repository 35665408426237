import './style/order-history.css';
import { useCallback, useMemo, useState } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { Button, CustomIcons } from '@procurenetworks/procure-component-library';
import Orders from 'app/i18n/Orders';
import EntityManager from 'app/modules/components/EntityManager';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { AllowedPermissionsSubjectEnum, ItemTypeEnum } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

import { adaptNodeEdgeToNode } from '../../../../components/AsyncMultiSelect/utils';
import { SnackbarService } from '../../../../components/Snackbar';
import { WarningService } from '../../../../components/WarningAlert';
import usePagination from '../../../../hooks/usePagination';
import OrderHistoryTable from './components/OrderHistoryTable';
import { useDeleteOrderRequestsMutation } from './graphql/mutations/generated/deleteBulkOrderRequests';
import { useDeleteOrderRequestMutation } from './graphql/mutations/generated/deleteOrderRequest';
import { useOrderHistoryTableQuery } from './graphql/query/generated/orderHistoryTable';
import useOrderHistoryFilters from './hook/useOrderHistoryFilters';
import useSearchSuggestion from './hook/useSearchSuggestion';
import { hasDeleteActionForOrderItems } from './utils/status';

function OrderHistory() {
  // const { search, table } = useEntityManager({
  //   selection: false,
  //   sortState: {
  //     id: 'createdAt',
  //     orderBy: 'desc',
  //   },
  // });

  const onDelete = (ids: string[], item?: object[]) => {};
  const { search, table } = useMultiSelectEntityManager({
    onDelete,
    hideActions: true,
    sortState: {
      id: 'createdAt',
      orderBy: 'desc',
    },
  });

  const [deletingOrderId, setDeletingOrderId] = useState<string | null>(null);
  const [{ fetching: deletingOrder }, executeDeleteOrderItem] = useDeleteOrderRequestMutation();
  const [{ fetching: deletingBulkOrder }, executeBulkDeleteOrderItem] =
    useDeleteOrderRequestsMutation();

  const { filtersState, setFilterState } = useOrderHistoryFilters();
  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  const { onNextPage, onPrevPage, onReset, fetching, data } = usePagination(
    useOrderHistoryTableQuery,
    {
      filters: {
        search: search.debouncedSearchText,
        destinationSiteIds: filtersState.destinationSiteIds,
        deliverToIds: filtersState.deliverToIds,
        createdByIds: filtersState.createdByIds,
        statuses: filtersState.statues,
        orderRequestTypes: filtersState.orderRequestTypes,
      },
      sorts: table.state.sorts,
    },
    {
      edgeKey: 'orderRequests',
      pageSize: table.state.numberOfRowsPerPage,
      requestPolicy: 'network-only',
    },
  );

  const rows = useMemo(() => {
    return data?.orderRequests?.edges?.map(adaptNodeEdgeToNode) || [];
  }, [data?.orderRequests?.edges]);

  const onDeleteOrder = useCallback(
    (orderRequestId: string) => {
      WarningService.showWarning({
        message: Orders.FormValidationMessages.confirmDelete,
        onConfirm: () => {
          setDeletingOrderId(orderRequestId);
          executeDeleteOrderItem({
            input: {
              orderRequestId,
            },
          })
            .then((response) => {
              if (response.data?.deleteOrderRequest?.success) {
                SnackbarService.show({
                  message: Orders.SuccessMessages.OrderDeleted,
                });
              }
            })
            .finally(() => {
              setDeletingOrderId(null);
            });
        },
      });
    },
    [executeDeleteOrderItem, setDeletingOrderId],
  );

  //function check which row item is make selectable or which not
  const isRowSelectable = useCallback((row: any) => {
    const orderItems = row.orderRequestItems;
    return hasDeleteActionForOrderItems(orderItems, row);
  }, []);

  //below function used to delete the bulk orders
  const handleBulkDelete = useCallback(() => {
    const selectedRowIds = table.state.selectedRowIds;

    WarningService.showWarning({
      message: Orders.FormValidationMessages.confirmBulkDelete,
      onConfirm: () => {
        executeBulkDeleteOrderItem({
          input: {
            orderRequestIds: selectedRowIds,
          },
        })
          .then((response: any) => {
            if (response.data?.deleteOrderRequests?.success) {
              SnackbarService.show({
                message: Orders.SuccessMessages.BulkOrderDeleted,
              });
              table.setState({ selectedRowIds: [] });
            }
          })
          .finally(() => {
            setDeletingOrderId(null);
          });
      },
    });
  }, [table.state.selectedRowIds]);
  return (
    <EntityManager
      className="rounded-bl-[12px] rounded-br-[12px] !p-[0]"
      subject={AllowedPermissionsSubjectEnum.OrderRequest}>
      <Box className="px-[24px]">
        <EntityManager.Title
          documentTitle={Orders.OrderHistoryDocumentTitle}
          title={Orders.OrderHistory}
          titleWrapperClass="border-t-[1px] border-grey-300"
        />
      </Box>

      <OrderHistoryTable
        count={data?.orderRequests?.totalCount || 0}
        deleteOrderState={{
          deletingOrder,
          deletingOrderId,
          deletingBulkOrder,
        }}
        fetching={fetching}
        filterNodes={
          <Stack justifyContent="between">
            <EntityManager.Search
              {...search}
              autoFocus
              isDropdown
              removedSearchQoutedValue
              placeholder="Search"
              startAdornment={
                <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                  <CustomIcons.SearchIcon />
                </InputAdornment>
              }
              suggestionData={searchSuggestions}
              onChangeSearch={setSearchValue}
            />
          </Stack>
        }
        filtersState={filtersState}
        isRowSelectable={isRowSelectable}
        paginationWrapper="p-[16px] flex-wrap lg:p-[24px] md:p-[24px]"
        rows={rows}
        setFilterState={setFilterState}
        table={table}
        onDeleteOrder={onDeleteOrder}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onReset={onReset}
      />
      <Box className="flex w-full justify-end py-[16px] pr-[24px]">
        <Button
          classes="min-w-[94px] h-[44px]"
          disabled={!table.state.selectedRowIds.length || deletingBulkOrder}
          loading={deletingBulkOrder}
          theme="danger"
          onClick={handleBulkDelete}>
          Bulk Delete
        </Button>
      </Box>
    </EntityManager>
  );
}

export default OrderHistory;
