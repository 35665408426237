import Orders from 'app/i18n/Orders';

import { OrderDetailsData } from '../../../../views/OrderDetail/type';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import { forwardRef, useMemo } from 'react';
import FormLabel from '../../../../../../components/ProcureForm/FormLabel';

interface Props {
  selectedValue?: {
    id: string;
    name: string;
  };
  options: {
    id: string;
    name: string;
  }[];
  isRequired?: boolean;
  disabled?: boolean;
  label?: string;
}

function getOptionLabel(option: any) {
  return option.name;
}

const ExternalOrderViewSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { selectedValue, isRequired, options, label, disabled } = props;

  const LabelNode = () => <FormLabel className='text-[14px] mb-4 font-medium text-grey-800'>{label}</FormLabel>;

  return (
    <div className="mb-20">
      <div ref={ref}>
        <LabelNode/>
        <Autocomplete
          disabled
          getOptionLabel={getOptionLabel}
          options={options}
          label={Orders.FormLabels.BillTo}
          renderInput={(params) => <TextField {...params} cleanInputIcon labelNode={LabelNode} />}
          size="medium"
          className='mt-6'
          value={selectedValue as any}
        />
      </div>
    </div>
  );
});

export default ExternalOrderViewSelect;
