import { Button, DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { capitalize } from 'lodash';
import moment from 'moment';
import TableOrderIdView from 'app/modules/orders/views/OrderHistory/components/OrderIdNode';

import Orders from '../../../../../i18n/Orders';
import { LocationTypeEnum, Maybe } from '../../../../../types/schema';
import SitesTableFilter from '../../../../sites/components/SitesTableFilter';
import SiteTableNode from '../../../../sites/components/SiteTableNode';
import UserEmailTableNode from '../../../../users/components/UserEmailTableNode';
import UsersTableFilter from '../../../../users/components/UsersTableFilter';
import UserTableNode from '../../../../users/components/UserTableNode';
import TableLeastItemStatusView from '../../../components/TableLeastItemStatusView';
import OrderStatusTableFilter from '../components/OrderStatusTableFilter';
import { hasDeleteActionForOrderItems } from './status';
import SyncStateWithUrlParam from 'app/utils/queryParams';
import OrderTypeTableFilter from '../components/OrderTypeTableFilter';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { useMemo } from 'react';
import { isExternalUserHavePartnerTenants } from '../../OrderRequest/utils/externalTenant';

const getOrderIdHeadCell = (config?: Partial<DataGridHeadCell>): DataGridHeadCell => ({
  id: 'orderRequestCode',
  label: 'Order ID',
  value: 'orderRequestCode',
  valueNode: ({ row }) => <TableOrderIdView row={row} />,
  sortable: true,
  width: 150,
  ...(config || {}),
});

const getOrderTypeHeadCell = (config?: Partial<DataGridHeadCell>): DataGridHeadCell => ({
  id: 'orderRequestType',
  label: 'Order Type',
  value: 'type',
  labelNode: (props) => {
    return <OrderTypeLabelNode props={props} />;
  },
  valueNode: ({ row }) => (
    <span className="truncate" title={capitalize(row.type)}>
      {capitalize(row.type)}
    </span>
  ),
  width: 200,
  ...(config || {}),
});

export const OrderTypeLabelNode = ({ props }: any) => {
  const { extraProps } = props.state;
  const { filtersState, setFilterState } = extraProps || {};

  const user = useCurrentUser();
  const isExternalUser = useMemo(() => isExternalUserHavePartnerTenants(user), [user]);

  return isExternalUser ? (
    <div className="table-filter" onClick={(e) => e.stopPropagation()}>
      <OrderTypeTableFilter
        key="orderType-table-filter"
        name="orderType"
        placeholder="Order Type"
        size="small"
        data-testid="order-type-filter"
        value={filtersState?.orderRequestTypes}
        onChange={(value) => {
          value == ''
            ? setFilterState?.({ orderRequestTypes: [] })
            : setFilterState?.({ orderRequestTypes: [value] });
        }}
      />
    </div>
  ) : (
    <span className="font-semibold">Order Type</span>
  );
};

const getOrderTitleHeadCell = (config?: Partial<DataGridHeadCell>): DataGridHeadCell => ({
  id: 'title',
  label: 'Order Title',
  value: 'title',
  sortable: true,
  width: 150,
  ...(config || {}),
});

const getDestinationSiteHeadCell = (config?: Partial<DataGridHeadCell>): DataGridHeadCell => ({
  id: 'destinationSiteIds',
  label: 'Ship To',
  labelNode: (props) => {
    const { extraProps } = props.state;
    const { filtersState, setFilterState } = extraProps || {};

    SyncStateWithUrlParam('destinationSiteIds', filtersState?.destinationSiteIds, setFilterState);

    return (
      <div>
        <SitesTableFilter
          key="destinationSiteIds-table-filter"
          name="createdBy"
          placeholder={Orders.FormLabels.ShipTo}
          queryVariables={{
            types: [LocationTypeEnum.Site, LocationTypeEnum.PartnerTenant],
          }}
          value={filtersState?.destinationSiteIds}
          onChange={(value: string[]) => {
            setFilterState?.({
              destinationSiteIds: value,
            });
          }}
        />
      </div>
    );
  },
  value: 'destinationSiteName',
  valueNode: ({ row }) => {
    return <SiteTableNode site={row.destinationSite} />;
  },
  ...(config || {}),
});

const getOrderDateHeadCell = (config?: Partial<DataGridHeadCell>): DataGridHeadCell => ({
  id: 'createdAt',
  label: 'Order Date',
  value: 'createdAt',
  valueNode: ({ row }) => (
    <span
      className="truncate"
      title={row.createdAt ? moment(row.createdAt).format('MM-DD-YYYY HH:mm') : ''}>
      {row.createdAt ? moment(row.createdAt).format('MM-DD-YYYY HH:mm') : ''}
    </span>
  ),
  sortable: true,
  ...(config || {}),
});

const getDueDateHeadCell = (config?: Partial<DataGridHeadCell>): DataGridHeadCell => ({
  id: 'dueDate',
  label: 'Due Date',
  value: 'dueDate',
  valueNode: ({ row }) => (
    <span
      className="truncate"
      title={row.dueDate ? moment(row.dueDate).utc().format('MM-DD-YYYY') : ''}>
      {row.dueDate ? moment(row.dueDate).utc().format('MM-DD-YYYY') : ''}
    </span>
  ),
  sortable: true,
  ...(config || {}),
});

const getStatusHeadCell = (config?: Partial<DataGridHeadCell>): DataGridHeadCell => ({
  id: 'status',
  label: 'Status',
  width: 230,
  labelNode: (props) => {
    const { extraProps } = props.state;
    const { filtersState, setFilterState } = extraProps || {};
    SyncStateWithUrlParam('statues', filtersState?.statues, setFilterState);

    return (
      <div>
        <OrderStatusTableFilter
          key="status-table-filter"
          name="status"
          placeholder={Orders.FormLabels.Status}
          value={filtersState?.statues}
          onChange={(value: string[]) => {
            setFilterState?.({
              statues: value,
            });
          }}
        />
      </div>
    );
  },
  value: 'status',
  valueNode: ({ row }) => <TableLeastItemStatusView status={row.status} />,
  sortable: true,
  ...(config || {}),
});

const getCreatedByHeadCell = (config?: Partial<DataGridHeadCell>): DataGridHeadCell => ({
  id: 'createdBy',
  label: 'Name',
  value: 'createdBy',
  labelNode: (props) => {
    const { extraProps } = props.state;
    const { filtersState, setFilterState } = extraProps || {};
    SyncStateWithUrlParam('createdByIds', filtersState?.createdByIds, setFilterState);

    return (
      <div>
        <UsersTableFilter
          key="createdBy-table-filter"
          name="createdBy"
          placeholder={Orders.FormLabels.Requester}
          queryVariables={{
            includingPartnerTenantExternalUsers: true,
          }}
          value={filtersState?.createdByIds}
          onChange={(value: Maybe<string[]>) => {
            setFilterState?.({
              createdByIds: value,
            });
          }}
        />
      </div>
    );
  },
  valueNode: ({ row }) => {
    return <UserTableNode user={row.createdBy} />;
  },
  ...(config || {}),
});

export const getOrderHistoryTableColumnsWithDeletePermission = (): DataGridHeadCell[] => [
  getOrderIdHeadCell(),
  getOrderTitleHeadCell(),
  getOrderTypeHeadCell(),
  getCreatedByHeadCell({
    label: 'Requestor',
  }),
  getDestinationSiteHeadCell(),
  {
    id: 'deliverToIds',
    label: 'Deliver To',
    labelNode: (props) => {
      const { extraProps } = props.state;
      const { filtersState, setFilterState } = extraProps || {};
      SyncStateWithUrlParam('deliverToIds', filtersState?.deliverToIds, setFilterState);

      return (
        <div>
          <UsersTableFilter
            key="deliverToIds-table-filter"
            name="deliverToIds"
            placeholder={Orders.FormLabels.DeliverTo}
            queryVariables={{
              includingPartnerTenantExternalUsers: true,
            }}
            value={filtersState?.deliverToIds}
            onChange={(value: Maybe<string[]>) => {
              setFilterState?.({
                deliverToIds: value,
              });
            }}
          />
        </div>
      );
    },
    value: 'deliverToName',
    valueNode: ({ row }) => {
      return <UserTableNode user={row.deliverTo} />;
    },
  },
  getOrderDateHeadCell(),
  getDueDateHeadCell(),
  getStatusHeadCell(),
  {
    id: 'action',
    label: 'Action',
    value: 'action',
    valueNode: ({ row, state }) => {
      const { extraProps } = state;
      const { deleteOrderState, onDeleteOrder } = extraProps || {};

      return (
        <Button
          classes="min-w-[94px] h-[44px]"
          disabled={
            !hasDeleteActionForOrderItems(row.orderRequestItems, row) ||
            deleteOrderState?.deletingOrder
          }
          loading={deleteOrderState?.deletingOrderId === row.id}
          theme="danger"
          onClick={() => {
            onDeleteOrder?.(row.id);
          }}>
          Delete
        </Button>
      );
    },
  },
];

export const getOrderHistoryTableColumnsWithoutDeletePermission = (): DataGridHeadCell[] => [
  getOrderIdHeadCell(),
  getOrderTitleHeadCell(),
  getCreatedByHeadCell({
    label: 'Name',
  }),
  {
    id: 'email',
    label: 'Email',
    value: 'email',
    valueNode: ({ row }) => {
      return <UserEmailTableNode user={row.createdBy} />;
    },
  },
  getDestinationSiteHeadCell(),
  {
    id: 'billToName',
    label: 'Bill To',
    value: 'billToSiteName',
    valueNode: ({ row }) => {
      return <SiteTableNode site={row.billToSite} />;
    },
  },
  getOrderDateHeadCell(),
  getDueDateHeadCell(),
  getStatusHeadCell(),
];
